var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.$apollo.loading || _vm.mutationLoading,"spinner-variant":"primary"}},[_c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"subtitle":null,"title":null,"back-button-text":"Previous","color":"#7367F0","finish-button-text":"Submit","shape":"square"},on:{"on-complete":_vm.formSubmitted}},[_c('tab-content',{attrs:{"before-change":_vm.validationForm,"title":"Basic Information"}},[_c('validation-observer',{ref:"startupBasicInfoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Basic Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Startup Name *","label-for":"startup"}},[_c('validation-provider',{attrs:{"name":"Startup Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"startup","state":errors.length > 0 ? false:null,"placeholder":"Startup Name","type":"text"},model:{value:(_vm.startup.title),callback:function ($$v) {_vm.$set(_vm.startup, "title", $$v)},expression:"startup.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Designation *","label-for":"designation"}},[_c('validation-provider',{attrs:{"name":"Designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"designation","state":errors.length > 0 ? false:null,"placeholder":"Your Designation","type":"text"},model:{value:(_vm.startup.designation),callback:function ($$v) {_vm.$set(_vm.startup, "designation", $$v)},expression:"startup.designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false:null,"placeholder":"Startup Description","rows":"2"},model:{value:(_vm.startup.description),callback:function ($$v) {_vm.$set(_vm.startup, "description", $$v)},expression:"startup.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Upload Competition Poster(Image)","label-for":"competitionPoster"}},[_c('validation-provider',{attrs:{"name":"File","rules":"size:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"state":errors.length > 0 ? false:null,"accept":"image/jpeg, image/png, image/gif","drop-placeholder":"Drop file here...","placeholder":"Choose a file or drop it here..."},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Registered Name","label-for":"registered_title"}},[_c('validation-provider',{attrs:{"name":"Registered Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registered_title","state":errors.length > 0 ? false:null,"placeholder":"Startup Registered Name","type":"text"},model:{value:(_vm.startup.registered_title),callback:function ($$v) {_vm.$set(_vm.startup, "registered_title", $$v)},expression:"startup.registered_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"CIN","label-for":"cin"}},[_c('validation-provider',{attrs:{"name":"CIN"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cin","state":errors.length > 0 ? false:null,"placeholder":"Startup Registration Number","type":"text"},model:{value:(_vm.startup.cin),callback:function ($$v) {_vm.$set(_vm.startup, "cin", $$v)},expression:"startup.cin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"DPIIT","label-for":"dpiit"}},[_c('validation-provider',{attrs:{"name":"DPIIT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dpiit","state":errors.length > 0 ? false:null,"placeholder":"Startup DPIIT Number","type":"text"},model:{value:(_vm.startup.dpiit),callback:function ($$v) {_vm.$set(_vm.startup, "dpiit", $$v)},expression:"startup.dpiit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Official Email *","label-for":"official_email"}},[_c('validation-provider',{attrs:{"name":"Official Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"official_email","state":errors.length > 0 ? false:null,"placeholder":"hello@yourbusiness.com","type":"text"},model:{value:(_vm.startup.official_email),callback:function ($$v) {_vm.$set(_vm.startup, "official_email", $$v)},expression:"startup.official_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Phone Number *","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"official_phone","state":errors.length > 0 ? false:null,"placeholder":"+91-1234567890","type":"text"},model:{value:(_vm.startup.official_phone),callback:function ($$v) {_vm.$set(_vm.startup, "official_phone", $$v)},expression:"startup.official_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Website","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Website"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false:null,"placeholder":"Startup Website URL","type":"text"},model:{value:(_vm.startup.url),callback:function ($$v) {_vm.$set(_vm.startup, "url", $$v)},expression:"startup.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null,"label":"Industry *","label-for":"industry"}},[_c('v-select',{attrs:{"id":"industry","options":_vm.industries,"multiple":"","placeholder":"Select Industry"},model:{value:(_vm.industryArray),callback:function ($$v) {_vm.industryArray=$$v},expression:"industryArray"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Lifecycle Stage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null,"label":"Lifecycle Stage *","label-for":"startup_stage"}},[_c('v-select',{attrs:{"id":"startup_stage","options":['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling'],"placeholder":"Select Lifecycle Stage"},model:{value:(_vm.startup.startup_stage),callback:function ($$v) {_vm.$set(_vm.startup, "startup_stage", $$v)},expression:"startup.startup_stage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"City *","label-for":"city"}},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"city","state":errors.length > 0 ? false:null,"placeholder":"City","type":"text"},model:{value:(_vm.startup.city),callback:function ($$v) {_vm.$set(_vm.startup, "city", $$v)},expression:"startup.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"State","label-for":"state"}},[_c('validation-provider',{attrs:{"name":"State"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"state","state":errors.length > 0 ? false:null,"placeholder":"State","type":"text"},model:{value:(_vm.startup.state),callback:function ($$v) {_vm.$set(_vm.startup, "state", $$v)},expression:"startup.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Country *","label-for":"country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"country","state":errors.length > 0 ? false:null,"placeholder":"Country","type":"text"},model:{value:(_vm.startup.country),callback:function ($$v) {_vm.$set(_vm.startup, "country", $$v)},expression:"startup.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Founders' Details"}},[_c('validation-observer',{ref:"startupFounderDetailsRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Founders' Details ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('founders-info',{ref:"foundersInfo",attrs:{"founders":_vm.startup.startups_founderstables},model:{value:(_vm.startup.startups_founderstables),callback:function ($$v) {_vm.$set(_vm.startup, "startups_founderstables", $$v)},expression:"startup.startups_founderstables"}})],1)],1)],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }